/* You can add global styles to this file, and also import other style files */

.toolbar-spacer {
  flex: 1 1 auto;
}

/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Additional styles to ensure 100% height */
html, body, app-root {
  height: 100%;
  overflow-y: hidden; /* Prevent viewport scrolling */
}

body {
  margin: 2px;
}
